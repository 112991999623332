<template>
	<b-overlay class="mb-2" :show="isLoading">
		<b-row>
			<b-col cols="12" lg="6" :xl="widthScreen > 1600 ? 3 : 6">
				<div class="card p-1 h-100 align-items-center">
					<span class="title align-self-start">RESULTADOS GENERALES</span>
					<div
						class="position-relative myDonut d-flex justify-content-center align-items-center"
						style="height: 360px; width: 360px"
					>
						<VueApexCharts
							type="donut"
							:width="getSize(widthScreen)"
							:height="getSize(widthScreen)"
							:options="donutOpts"
							:series="donutData"
						/>
						<div class="container-text">
							<p class="number mb-25 mb-md-1">{{ donutData.reduce((pre, cur) => cur + pre, 0) }}</p>
							<p class="text">Total</p>
						</div>
					</div>

					<div class="container-legend">
						<template v-for="(item, key) in donutOpts.labels">
							<div class="item-legend" :key="key">
								<!-- :style="{ background: item. }" -->
								<p class="mb-0 title">
									{{ item }}
								</p>
								<div class="d-flex align-items-center mt-25 mt-md-50">
									<div
										style="height: 18px; width: 18px; border-radius: 50%"
										:style="{ background: donutOpts.colors[key] }"
									></div>
									<p class="mb-0 ml-1 number">{{ donutData[key] }}</p>
								</div>
							</div>
						</template>
					</div>
				</div>
			</b-col>
			<b-col cols="12" lg="6" :xl="widthScreen > 1600 ? 9 : 6" class="mt-2 mt-md-0">
				<div class="card p-1 h-100">
					<span class="title">RESULTADOS POR MES</span>
					<div class="container-bars">
						<div class="bars">
							<VueApexCharts type="bar" height="400" :options="barOpts" :series="barData" />
						</div>
					</div>
				</div>
			</b-col>
		</b-row>
	</b-overlay>
</template>

<script>
import tournamentService from "@/services/tournament/default.service"

import VueApexCharts from "vue-apexcharts"

export default {
	components: { VueApexCharts },
	props: { season: Object },
	data: () => ({
		isLoading: false,
		barData: [
			{
				name: "Triunfo",
				data: new Array(12).fill(10),
			},
			{
				name: "Empate",
				data: new Array(12).fill(20),
			},
			{
				name: "Derrota",
				data: new Array(12).fill(30),
			},
		],
		barOpts: {
			labels: [
				"Enero",
				"Febrero",
				"Marzo",
				"Abril",
				"Mayo",
				"Junio",
				"Julio",
				"Agosto",
				"Septiembre",
				"Octubre",
				"Noviembre",
				"Diciembre",
			],
			colors: ["#33d491", "#ecdc4d", "#ff4d4d", "#6258cc", "#5148a8", "#3f3984", "#2e2960", "#0b0a18"],
			legend: { position: "bottom", fontWeight: 600, labels: { colors: "#82868b" } },
		},
		donutData: [1, 2, 3],
		donutOpts: {
			labels: ["Triunfos", "Empates", "Derrotas"],
			colors: ["#33d491", "#ecdc4d", "#ff4d4d", "#6258cc", "#5148a8", "#3f3984", "#2e2960", "#0b0a18"],
			legend: { position: "bottom", fontWeight: 600, labels: { colors: "#82868b" } },
		},
	}),
	computed: {
		widthScreen() {
			return this.$store.state.app.windowWidth
		},
	},
	methods: {
		efectivityColor(idx) {
			switch (idx) {
				case 0:
					return "text-success"
				case 1:
					return "text-warning"
				case 2:
					return "text-danger"
				case 3:
					return "text-info"
				default:
					return "text-secondary"
			}
		},
		async getData() {
			this.isLoading = true
			const { data } = await tournamentService.getDsMonthlyResults({
				start_date: this.season.start,
				end_date: this.season.end,
			})
			this.barData = [
				{
					name: "Triunfo",
					data: data.map((m) => Number(m.triunfos)),
				},
				{
					name: "Empate",
					data: data.map((m) => Number(m.empates)),
				},
				{
					name: "Derrota",
					data: data.map((m) => Number(m.derrotas)),
				},
			]
			this.barOpts = {
				labels: data.map((m) => m.name.slice(0, 3).toUpperCase() + ` ${m.effectivity}%`),
				colors: ["#33d491", "#ecdc4d", "#ff4d4d", "#6258cc", "#5148a8", "#3f3984", "#2e2960", "#0b0a18"],
				legend: {
					position: "bottom",
					fontWeight: 600,
					labels: { colors: "#82868b" },
					showForSingleSeries: true,
				},
				plotOptions: { bar: { borderRadius: 4, borderRadiusApplication: "end" } },
				stroke: { colors: "transparent", width: 5 },
				yaxis: {
					title: {
						text: "Cantidad de resultados",
						style: {
							color: "#9aa0ac",
						},
					},
					labels: {
						style: {
							colors: ["#9aa0ac"],
						},
					},
				},
				fill: {
					type: "gradient",
					gradient: {
						shade: "light",
						type: "horizontal",
						shadeIntensity: 0.25,
						gradientToColors: undefined,
						inverseColors: true,
						opacityFrom: 0.85,
						opacityTo: 0.85,
						stops: [50, 0, 100],
					},
				},
			}
			const { data: gData } = await tournamentService.getDsGeneralResults({
				start_date: this.season.start,
				end_date: this.season.end,
			})
			this.donutData = [
				gData.results.find((r) => r.id == 1).counter,
				gData.results.find((r) => r.id == 2).counter,
				gData.results.find((r) => r.id == 3).counter,
			]
			const totalCount = this.donutData.reduce((pre, cur) => cur + pre, 0)
			this.donutOpts = {
				labels: ["Triunfos", "Empates", "Derrotas"],
				colors: ["#33d491", "#ecdc4d", "#ff4d4d", "#6258cc", "#5148a8", "#3f3984", "#2e2960", "#0b0a18"],
				legend: {
					// position: "bottom", fontWeight: 600, labels: { colors: "#82868b" }
					show: false,
				},
			}
			this.isLoading = false
		},
		getSize(number) {
			let sizeData = 360
			if (number <= 420) {
				sizeData = 290
			}
			return sizeData
		},
	},
	created() {
		this.getData()
	},
	watch:{
		'season.start'(){
			this.getData()
		}
	}
}
</script>

<style scoped lang="scss">
.title {
	display: block;
	margin-bottom: 2rem;
	color: var(--primary);
	font-size: 1.5rem;
	font-weight: 700;
}

.chadow {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 9rem;
	aspect-ratio: 1/1;
	background: transparent;
	box-shadow: 0 0 0.5rem #000;
	border-radius: 50%;
	transform: translate(-50%, -64%);
}

@media screen and (min-width: 768px) {
	.chadow {
		width: 12rem;
		transform: translate(-50%, -62%);
	}
}

@media screen and (min-width: 1024px) {
	.chadow {
		width: 40%;
		transform: translate(-50%, -98%);
	}
}

@media screen and (min-width: 1440px) {
	.chadow {
		width: 40%;
		transform: translate(-50%, -62%);
	}
}

.container-text {
	background: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	height: 30%;
	width: 30%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	// box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	box-shadow: 0 0 21px rgba(0, 0, 0, 0.17);
	@media screen and (min-width: 420px) {
		height: 40%;
		width: 40%;
	}
	@media screen and (min-width: 1024px) {
		height: 40%;
		width: 40%;
	}
	.number {
		font-size: 30px;
		color: #434343;
		font-weight: bolder;
		@media screen and (min-width: 420px) {
			font-size: 40px;
		}
	}
	.text {
		margin-bottom: 0;
		font-size: 16px;
		color: #909090;
		@media screen and (min-width: 420px) {
			font-size: 20px;
		}
	}
}

.container-legend {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0;
	@media screen and (min-width: 1024px) {
		padding: 0 1rem;
	}
	@media screen and (min-width: 1800px) {
		padding: 0 2rem;
	}
	.item-legend {
		display: flex;
		flex-direction: column;
		align-items: center;
		.title {
			color: #909090;
			font-size: 16px;
			font-weight: 500;
			font-size: 12px;
			@media screen and (min-width: 1024px) {
				font-size: 14px;
			}
			@media screen and (min-width: 1800px) {
				font-size: 18px;
			}
		}
		.number {
			color: #434343;
			font-weight: bolder;
			font-size: 12px;
			@media screen and (min-width: 1024px) {
				font-size: 14px;
			}
			@media screen and (min-width: 1800px) {
				font-size: 17px;
			}
		}
	}
}

.container-bars {
	overflow: auto;
	.bars {
		min-width: 1000px;
	}
}
</style>

<style>
.myDonut .apexcharts-legend-series {
	flex-direction: column-reverse;
}

.apexcharts-toolbar {
	background: red !important;
	display: none !important;
}
</style>
