<template>
	<b-overlay class="mb-2 card p-2" :show="isLoading">
		<b-row class="gap-y-2">
			<b-col cols="12" lg="6">
				<div class="Card">
					<div class="Card__container">
						<span class="Card__title">RANKING POR MADRILLA</span>
						<span class="Card__effectivity">
							<feather-icon class="mr-05" icon="CircleIcon" />
							EFECTIVIDAD
						</span>
					</div>
					<div>
						<VueApexCharts type="bar" height="400" :options="madrillaOpts" :series="madrillaData" />
					</div>
				</div>
			</b-col>
			<b-col cols="12" lg="6">
				<div class="Card">
					<div class="Card__container">
						<span class="Card__title">RANKING POR PADRILLO</span>
						<span class="Card__effectivity">
							<feather-icon class="mr-05" icon="CircleIcon" />
							EFECTIVIDAD
						</span>
					</div>
					<div>
						<VueApexCharts type="bar" height="400" :options="padrilloOpts" :series="padrilloData" />
					</div>
				</div>
			</b-col>
		</b-row>
	</b-overlay>
</template>

<script>
import tournamentService from "@/services/tournament/default.service"
import labelUtil from "../chartLabelUtil"

import VueApexCharts from "vue-apexcharts"

export default {
	components: { VueApexCharts },
	props: { season: Object },
	data: () => ({
		isLoading: false,
		tournamentAvatars: [],
		madrillaData: [
			{
				name: "Efectividad",
				data: new Array(5).fill(20),
			},
		],
		madrillaOpts: {
			labels: ["test1", "test2", "test3", "test4", "test5"],
			colors: ["#33D491"],
			legend: { position: "bottom" },
			plotOptions: { bar: { borderRadius: 8, borderRadiusApplication: "end" } },
		},
		padrilloData: [
			{
				name: "Efectividad",
				data: new Array(5).fill(30),
			},
		],
		padrilloOpts: {
			labels: ["test1", "test2", "test3", "test4", "test5"],
			colors: ["#33D491"],
			legend: { position: "bottom" },
			plotOptions: { bar: { borderRadius: 8, borderRadiusApplication: "end" } },
		},
		tournamentAwards: [],
	}),
	methods: {
		async getData() {
			this.isLoading = true
			const [{ data: mData }, { data: pData }] = await Promise.all([
				tournamentService.getDsParentResults({
					start_date: this.season.start,
					end_date: this.season.end,
					category_id: 2,
				}),
				tournamentService.getDsParentResults({
					start_date: this.season.start,
					end_date: this.season.end,
					category_id: 1,
				}),
			])

			this.madrillaData = [
				{
					name: "Efectividad",
					data: mData.map((m) => Number(m.points)),
				},
			]
			this.madrillaOpts = {
				labels: mData.map((m) => m.madrilla.plate || m.madrilla.alias),
				colors: ["#33D491"],
				legend: { position: "bottom" },
				dataLabels: {
					formatter: function (value, { dataPointIndex }) {
						const match = mData[dataPointIndex]
						if (!match) return value
						return value + `(${match.effectivity}%)`
					},
					style: { colors: ["#fff"] },
				},
				fill: {
					type: "gradient",
					gradient: {
						shade: "light",
						type: "horizontal",
						shadeIntensity: 0.25,
						gradientToColors: undefined,
						inverseColors: true,
						opacityFrom: 0.85,
						opacityTo: 0.85,
						stops: [50, 0, 100],
					},
				},
				yaxis: {
					title: {
						text: "Puntos conseguidos",
						style: {
							color: "#9aa0ac",
						},
					},
					labels: {
						style: {
							colors: ["#9aa0ac"],
						},
					},
				},
				tooltip: {
					enabled: true,
					y: {
						show: true,
						formatter: function (value, { dataPointIndex }) {
							const match = mData[dataPointIndex]
							if (!match) return value
							return `${match.effectivity}%`
						},
					},
					x: {
						show: true,
						formatter: function (value, { dataPointIndex }) {
							const match = mData[dataPointIndex]
							if (!match) return value
							return `${match.madrilla.alias ?? ""}(${match.madrilla.plate})`
						},
					},
				},
				// chart: {
				// 	events: {
				// 		xAxisLabelClick: ()=>console.log(".//././."),
				// 	},
				// },
				chart: {
					events: {
						dataPointSelection: (e, result_id, result_alter) =>
							this.openSpecimen(mData[result_alter.dataPointIndex], "madrilla"),
						click: () => {},
						selection: () => {},
					},
				},
			}

			this.padrilloData = [
				{
					name: "Efectividad",
					data: pData.map((p) => Number(p.points)),
				},
			]
			this.padrilloOpts = {
				labels: pData.map((p) => p.padrillo.plate || p.padrillo.alias),
				colors: ["#33D491"],
				legend: { position: "bottom" },
				dataLabels: {
					formatter: function (value, { dataPointIndex }) {
						const match = pData[dataPointIndex]
						if (!match) return value
						return value + `(${match.effectivity}%)`
					},
					style: { colors: ["#fff"] },
				},
				fill: {
					type: "gradient",
					gradient: {
						shade: "light",
						type: "horizontal",
						shadeIntensity: 0.25,
						gradientToColors: undefined,
						inverseColors: true,
						opacityFrom: 0.85,
						opacityTo: 0.85,
						stops: [50, 0, 100],
					},
				},
				yaxis: {
					title: {
						text: "Puntos conseguidos",
						style: {
							color: "#9aa0ac",
						},
					},
					labels: {
						style: {
							colors: ["#9aa0ac"],
						},
					},
				},
				tooltip: {
					enabled: true,
					y: {
						show: true,
						formatter: function (value, { dataPointIndex }) {
							const match = pData[dataPointIndex]
							if (!match) return value
							return `${match.effectivity}%`
						},
					},
					x: {
						show: true,
						formatter: function (value, { dataPointIndex }) {
							const match = pData[dataPointIndex]
							if (!match) return value
							return `${match.padrillo.alias ?? ""}(${match.padrillo.plate})`
						},
					},
				},
				chart: {
					events: {
						dataPointSelection: (e, result_id, result_alter) =>
							this.openSpecimen(pData[result_alter.dataPointIndex], "padrillo"),
					},
				},
			}

			this.isLoading = false
		},
		openSpecimen(specimen, category = "madrilla") {
			console.log("AS", specimen)
			window.open(`/ejemplares/detalle/${specimen[category].id}`, "_blank")
		},
	},
	created() {
		this.getData()
	},
	watch: {
		"season.start"() {
			this.getData()
		},
	},
}
</script>

<style scoped lang="scss">
.Card {
	border-radius: 0.5rem;
	box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
}

.Card__container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
}

.Card__title {
	color: var(--primary);
	font-size: 1.2rem;
	font-weight: 600;
	font-size: 12px;
	@media screen and (min-width: 1200px) {
		font-size: 16px;
	}
}

.Card__effectivity {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.2rem 0.5rem;
	color: var(--success);
	background: rgba(40, 199, 111, 0.2);
	border-radius: 0.5rem;
	font-weight: 500;
	font-size: 12px;
	@media screen and (min-width: 1200px) {
		font-size: 14px;
	}
}

.awardsContainer {
	display: grid;
	grid-template-columns: 2rem 1fr 1fr 1fr 1fr 1fr;
	width: 100%;
	padding: 0 0.5rem;
	padding-bottom: 0.5rem;
}

.awardsItem {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
</style>
