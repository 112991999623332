function convertCoords(x, y, svg, locatable) {
	var offset = svg.getBoundingClientRect()
	var matrix = locatable.getScreenCTM()

	return {
		x: matrix.a * x + matrix.c * y + matrix.e - offset.left,
		y: matrix.b * x + matrix.d * y + matrix.f - offset.top,
	}
}

function defineBgImage(defs, avatars, avatarIdx) {
	const hash = (Math.random() + 1).toString(36).substring(8)
	const id = "tournamentImgBg" + avatarIdx + hash
	const pattern = document.createElementNS("http://www.w3.org/2000/svg", "pattern")
	pattern.setAttribute("id", id)
	pattern.setAttribute("width", "100%")
	pattern.setAttribute("height", "100%")
	pattern.setAttribute("patternContentUnits", "objectBoundingBox")
	pattern.setAttribute("preserveAspectRatio", "xMidYMid slice")

	const image = document.createElementNS("http://www.w3.org/2000/svg", "image")
	image.setAttribute("href", avatars[avatarIdx])
	image.setAttribute("width", "1")
	image.setAttribute("height", "1")
	image.setAttribute("preserveAspectRatio", "xMidYMid slice")

	pattern.appendChild(image)
	defs.appendChild(pattern)

	return id
}

function createSvgImage(svg, label, imgId) {
	const g = document.createElementNS("http://www.w3.org/2000/svg", "g")
	// const title = document.createElementNS("http://www.w3.org/2000/svg", "title")
	// title.textContent = this.tournamentOpts.labels[titleIdx]
	// g.appendChild(title)
	const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle")
	circle.setAttribute("cx", label.x)
	circle.setAttribute("cy", label.y + 10)
	circle.setAttribute("r", "20")
	circle.setAttribute("fill", `url(#${imgId})`)
	g.appendChild(circle)
	svg.appendChild(g)
}

export default {
	createSvgImage,
	defineBgImage,
	convertCoords,
}
