<template>
	<b-modal centered hide-footer size="xmd" :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-row class="mb-1">
				<b-col cols="12" md="6" class="d-flex align-items-center mb-1 mb-md-0">
					<span>
						Mostrando {{ pagination.from }} a {{ pagination.to }} de {{ pagination.total }} registros
					</span>
				</b-col>
				<b-col cols="12" md="6" class="d-flex justify-content-md-end">
					<b-pagination
						class="m-0"
						pills
						hide-goto-end-buttons
						hide-ellipsis
						v-model="pagination.page"
						:total-rows="pagination.total"
						:per-page="pagination.perPage"
						@input="getRows"
					/>
				</b-col>
			</b-row>
			<b-row class="mb-2">
				<b-col cols="0" md="6"></b-col>
				<b-col>
					<b-input-group>
						<b-form-input placeholder="Placa, Alias, Rival..." v-model="search" @keypress.enter="getRows" />
						<b-input-group-append>
							<b-button class="btn-icon" variant="primary" @click="getRows">
								<feather-icon icon="SearchIcon" />
							</b-button>
						</b-input-group-append>
					</b-input-group>
				</b-col>
			</b-row>
			<b-table-simple class="text-center" sticky-header="80vh">
				<b-thead>
					<b-tr>
						<b-th>Ejemplar</b-th>
						<b-th>Rival</b-th>
						<b-th>Torneo</b-th>
						<b-th>Fecha</b-th>
						<b-th>Video</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="(row, idx) in rows" :key="idx">
						<b-td>
							<SpecimenPlate :specimen="row.specimen" />
							<small class="d-block mt-05 text-left">
								<b-badge :variant="colorizeSpecimenStatus(row.status_local)">
									Estado:
									<b>{{ row.status_local }}</b>
								</b-badge>
							</small>
							<small class="d-block mt-05 text-left">
									Origen:
								<b>{{ row.origin.name }}</b>
							</small>
						</b-td>
						<b-td>
							{{ row.rival_name }}
						</b-td>
						<b-td>
							{{ row.tournament_name }}
						</b-td>
						<b-td>
							{{ row.date | myGlobalDayShort }}
						</b-td>
						<b-td>
							<div v-if="row.video">
								<span v-if="row.video.status == 2" @click="ValidStatusVideo(row, idx)" style="cursor: pointer">
									<b-avatar
										:src="row.video.status == 2 ? row.video.thumb : null"
										rounded
										size="80"
										icon="film"
										:badge="row.video.duration"
										badge-variant="danger"
									>
									<template #badge>{{ row.video.duration }}</template>
									</b-avatar>
								</span>
								<p v-else>Procesando Video</p>
							</div>
							<p v-else>Sin video</p>
						</b-td>
					</b-tr>
					<b-tr v-if="rows.length == 0">
						<b-td colspan="6">NO hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import { mapActions } from "vuex"
import tournamentService from "@/services/tournament/default.service"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import videosService from "@/services/videos.service"

export default {
	components: { SpecimenPlate },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: "",
		pagination: {
			page: 1,
			perPage: 20,
			total: 0,
			from: 0,
			to: 0,
		},
		search: null,
		rows: [],
	}),
	methods: {
		...mapActions("header_book", ["toggleModal", "setIndexImage", "setImages"]),
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.pagination = {
				page: 1,
				perPage: 20,
				total: 0,
				from: 0,
				to: 0,
			}
			this.search = null
			this.$emit("closing")
		},
		async getRows() {
			this.isLoading = true
			const { data } = await tournamentService.getDsGeneralList({
				start_date: this.info.season.start,
				end_date: this.info.season.end,
				result_id: this.info._result,
				page: this.pagination.page,
				perpage: this.pagination.perPage,
				search: this.search,
			})	
			this.pagination.from = data.from
			this.pagination.to = data.to
			this.pagination.total = data.total
			if (this.pagination.page > data.last_page) this.pagination.page = data.last_page
			this.pagination.from = data.from

			this.rows = data.data
			// this.rows.forEach(element => {
			// 	if(element.status_local == "ACTIVO"){
			// 		element.status_local= "LIMPIO"
			// 	}
			// });
			this.isLoading = false
		},
		colorizeSpecimenStatus(status) {
			switch (status) {
				case "LIMPIO":
					return "success"
				case "MUERTO":
					return "danger"
				case "HERIDO":
					return "warning"
				default:
					return "secondary"
			}
		},
		showTopeVideo(row) {
			const local = !!row.specimen.alias
				? `${row.specimen.alias} (${row.specimen.plate})`
				: `${row.specimen.plate}`
			this.setImages([
				{
					...row.video,
					type: "video",
					url: row.video.uid,
					text: `${local} VS ${row.rival_name}`,
					specimen: { id: row.specimen.id },
				},
			])
			this.setIndexImage(0)
			this.toggleModal()
		},
		async ValidStatusVideo(row, i) {
			const { data: valid } = await videosService.validStatusVideo({
				id: row.video.uid,
			})
			if (valid.valid) {
				this.rows[i].video.status = 2;
				this.showTopeVideo(row)
			} else {
				this.showToast("warning", "top-right", "Resultados", "WarningIcon", "El video no esta listo todavia")
			}
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				const strResult = ["TRIUNFO", "EMPATE", "DERROTA"]
				this.title = `Lista de ejemplares con ${strResult[this.info._result - 1]}`
				this.getRows()
			}
		},
	},
}
</script>
<style>
.b-avatar-badge {
	font-size: 13px !important;
	line-height: 13.5px !important;
}
</style>
