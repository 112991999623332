<template>
	<b-overlay class="mb-2 card p-2" :show="isLoading">
		<b-row class="gap-y-2">
			<b-col cols="12">
				<div class="Card">
					<div>
						<b-row class="p-1 gap-y-1">
							<b-col cols="12" md="10">
								<b-row class="gap-y-1">
									<b-col cols="12" lg="3" class="d-flex align-items-center">
										<span class="Card__title text-nowrap">RESULTADOS DE TORNEOS</span>
									</b-col>
									<b-col cols="12" lg="9" class="d-flex align-items-center">
										<b-input-group prepend="Ordenar por" size="sm" style="width: 20rem;">
											<b-form-select
												:options="chartTypeOpts"
												v-model="chartType"
												@change="getData"
											/>
										</b-input-group>
									</b-col>
								</b-row>
							</b-col>
							<b-col cols="12" md="2" class="d-flex justify-content-end">
								<span class="Card__effectivity">
									<feather-icon class="mr-05" icon="CircleIcon" />
									<span v-if="chartType == 'effectivity'">EFECTIVIDAD</span>
									<span v-else>PELEAS</span>
								</span>
							</b-col>
						</b-row>
					</div>
					<div class="overflow-auto">
						<div class="position-relative" :style="{ minWidth: '800px', width: chartWidth }">
							<div class="Legend">
								<span class="Legend__item">
									<span class="Legend__box Legend__box--green"></span>
									<span class="Legend__text">Triunfo</span>
								</span>
								<span class="Legend__item">
									<span class="Legend__box Legend__box--yellow"></span>
									<span class="Legend__text">Empate</span>
								</span>
								<span class="Legend__item">
									<span class="Legend__box Legend__box--red"></span>
									<span class="Legend__text">Derrota</span>
								</span>
							</div>
							<VueApexCharts
								ref="tChart"
								type="bar"
								height="400"
								:options="tournamentOpts"
								:series="tournamentData"
							/>
							<div class="awardsContainer" :style="{ 'grid-template-columns': awardColumns }" v-if="1">
								<div class="awardsItem"></div>
								<div class="awardsItem" v-for="(extra, idx) in tournamentExtra" :key="idx">
									<span class="text-nowrap" v-b-tooltip.hover="`Efectividad`">
										<span class="font-weight-bolder text-primary">E</span>
										:
										<span>{{ extra.effectivity }}%</span>
									</span>
									<span class="text-nowrap">
										<v-icon
											v-if="!!extra.award.position"
											name="fa-trophy"
											scale="0.9"
											:style="{ color: colorizeAwardIcon(extra.award.position) }"
											v-b-tooltip.hover="`Posición: ${extra.award.position}º`"
										/>
										<span v-b-tooltip.hover="`Posición: ---`" v-else>---</span>
										:
										<span v-b-tooltip.hover="`Cantidad de premios`">
											{{ extra.award.counter || "---" }}
										</span>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</b-col>
		</b-row>
	</b-overlay>
</template>

<script>
import tournamentService from "@/services/tournament/default.service"
import labelUtil from "../chartLabelUtil"

import VueApexCharts from "vue-apexcharts"

export default {
	components: { VueApexCharts },
	props: { season: Object },
	data: () => ({
		isLoading: false,
		chartTypeOpts: [
			{ value: "effectivity", text: "Efectividad" },
			{ value: "peleas", text: "Cantidad de peleas" },
		],
		chartType: "effectivity",
		tournamentData: [
			{
				name: "Efectividad",
				data: new Array(5).fill(10),
			},
			{
				name: "Efectividad",
				data: new Array(5).fill(20),
			},
			{
				name: "Efectividad",
				data: new Array(5).fill(20),
			},
		],
		tournamentOpts: {
			labels: ["test1", "test2", "test3"],
			colors: ["#33d491", "#ecdc4d", "#ff4d4d"],
			legend: { show: false },
			plotOptions: { bar: { borderRadius: 8, borderRadiusApplication: "end" } },
		},
		tournamentAvatars: [],
		tournamentExtra: [],
		chartWidth: "auto",
		awardColumns: "2rem repeat(15, 1fr)",
	}),
	methods: {
		async getData() {
			this.isLoading = true
			const [{ data: tData }] = await Promise.all([
				tournamentService.getDsTournamentResults({
					start_date: this.season.start,
					end_date: this.season.end,
					order_by: this.chartType,
				}),
			])

			// this.tournamentData = [
			// 	{
			// 		name: "Efectividad",
			// 		data: tData.map((t) => Number(t.points)),
			// 	},
			// ]

			this.awardColumns = `3rem repeat(${tData.length}, 1fr)`
			if (tData.length > 6) {
				this.chartWidth = `${800 + tData.length * 100}px`
			}

			this.tournamentData = [
				{
					name: "Triunfo",
					data: tData.map((t) => Number(t.triunfos)),
				},
				{
					name: "Empate",
					data: tData.map((t) => Number(t.empates)),
				},
				{
					name: "Derrota",
					data: tData.map((t) => Number(t.derrotas)),
				},
			]

			this.tournamentOpts = {
				labels: tData.map((t) => t.name),
				colors: ["#33d491", "#ecdc4d", "#ff4d4d"],
				legend: { show: false },
				plotOptions: {
					bar: {
						borderRadius: 4,
						borderRadiusApplication: "end",
						// columnWidth: "80%",
					},
				},
				stroke: { colors: "transparent", width: 5 },
				xaxis: {
					labels: { minHeight: 50, formatter: () => "." },
				},
				fill: {
					type: "gradient",
					gradient: {
						shade: "light",
						type: "horizontal",
						shadeIntensity: 0.25,
						gradientToColors: undefined,
						inverseColors: true,
						opacityFrom: 0.85,
						opacityTo: 0.85,
						stops: [50, 0, 100],
					},
				},
				tooltip: {
					enabled: true,
					x: { show: true, formatter: (a) => a },
					y: {
						show: true,
						// formatter: function (value, { dataPointIndex }) {
						// 	const match = tData[dataPointIndex]
						// 	if (!match) return value
						// 	return `${match.effectivity}%`
						// },
					},
				},
				dataLabels: {
					// formatter: function (value, { dataPointIndex }) {
					// 	const match = tData[dataPointIndex]
					// 	if (!match) return value
					// 	return value
					// },
					style: { colors: ["#000"] },
				},
				yaxis: {
					title: {
						text: "Peleas conseguidos",
						style: {
							color: "#9aa0ac",
						},
					},
					labels: {
						style: {
							colors: ["#9aa0ac"],
						},
					},
				},
				fill: {
					type: "gradient",
					gradient: {
						shade: "light",
						type: "horizontal",
						shadeIntensity: 0.25,
						gradientToColors: undefined,
						inverseColors: true,
						opacityFrom: 0.85,
						opacityTo: 0.85,
						stops: [50, 0, 100],
					},
				},
			}

			this.tournamentAvatars = tData.map((t) => t.avatar_thumb)
			this.tournamentExtra = tData.map((t) => ({
				award: t.awards,
				effectivity: t.effectivity,
			}))
			setTimeout(() => this.setLabelImages(), 500)

			this.isLoading = false
		},
		setLabelImages() {
			const chart = this.$refs.tChart
			const el = chart.$el
			const svg = el.querySelector("svg")
			const defs = svg.querySelector("defs")

			const labels = Array.from(el.querySelectorAll(".apexcharts-xaxis-label"))
			const titles = labels.map((s) => s.querySelector("tspan"))
			titles.forEach((el, idx) => {
				const box = el.getBBox()
				const mdX = box.x + box.width / 2
				const mdY = box.y + box.height / 2
				const pos = labelUtil.convertCoords(mdX, mdY, svg, el)
				const label = { x: pos.x, y: pos.y }
				const imgId = labelUtil.defineBgImage(defs, this.tournamentAvatars, idx)
				labelUtil.createSvgImage(svg, label, imgId)
			})
		},
		colorizeAwardIcon(position) {
			switch (position) {
				case 1:
					return "#FFD700"
				case 2:
					return "#C0C0C0"
				case 3:
					return "#CD7F32"
				default:
					return "gray"
			}
		},
	},
	created() {
		this.getData()
	},
	watch: {
		"season.start"() {
			this.getData()
		},
	},
}
</script>

<style scoped lang="scss">
.Card {
	border-radius: 0.5rem;
	box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
}

.Card__container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
}

.Card__title {
	display: flex;
	align-items: center;
	gap: 2rem;
	color: var(--primary);
	font-size: 1.2rem;
	font-weight: 600;
	font-size: 12px;
	@media screen and (min-width: 1200px) {
		font-size: 16px;
	}
}

.Card__effectivity {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.2rem 0.5rem;
	color: var(--success);
	background: rgba(40, 199, 111, 0.2);
	border-radius: 0.5rem;
	font-weight: 500;
	font-size: 12px;
	@media screen and (min-width: 1200px) {
		font-size: 14px;
	}
}

.Legend {
	display: flex;
	justify-content: center;
	gap: 1rem;
	margin-bottom: 0.5rem;

	&__item {
		display: flex;
		justify-items: center;
		align-items: center;
		gap: 0.2rem;
	}

	&__box {
		width: 0.8rem;
		aspect-ratio: 1/1;
		background: var(--secondary);

		&--green {
			background: #33d491;
		}
		&--yellow {
			background: #ecdc4d;
		}
		&--red {
			background: #ff4d4d;
		}
	}

	&__text {
		color: #82868b;
		font-weight: 600;
		font-size: 0.8rem;
	}
}

.awardsContainer {
	display: grid;
	// grid-template-columns: 2rem repeat(15, 1fr);
	width: 100%;
	padding: 0 0.5rem;
	padding-bottom: 0.5rem;
}

.awardsItem {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
</style>
