<template>
	<b-overlay class="mb-2 card p-2" :show="isLoading">
		<span class="title">RESULTADOS POR ORIGEN</span>
		<b-row class="gap-y-2">
			<b-col cols="12" lg="6">
				<div class="Card">
					<div class="Card__container">
						<span class="Card__title">CAMADAS</span>
						<span class="Card__effectivity">{{ efectivities.litter }}% EFECTIVIDAD</span>
					</div>
					<div class="position-relative">
						<VueApexCharts
							type="pie"
							height="300"
							:options="sumZero(litterData) == 1 && litterData.length == 1 ? pieOptsZero : pieOpts"
							:series="litterData"
						/>
						<div class="pieShadow">
							<div class="dot"></div>
						</div>
					</div>
				</div>
			</b-col>
			<b-col cols="12" lg="6">
				<!-- aqui -->
				<div class="Card">
					<div class="Card__container">
						<span class="Card__title">EXTERNOS</span>
						<span class="Card__effectivity">{{ efectivities.external }}% EFECTIVIDAD</span>
					</div>
					<div>
						<VueApexCharts
							type="pie"
							height="300"
							:options="sumZero(externalData) == 1 && externalData.length == 1 ? pieOptsZero : pieOpts"
							:series="externalData"
						/>
					</div>
				</div>
			</b-col>
		</b-row>
	</b-overlay>
</template>

<script>
import tournamentService from "@/services/tournament/default.service"

import VueApexCharts from "vue-apexcharts"

export default {
	components: { VueApexCharts },
	props: { season: Object },
	data: () => ({
		isLoading: false,
		efectivities: { litter: 0, external: 0 },
		litterData: [],
		externalData: [],
		pieOpts: {
			labels: ["Triunfos", "Empates", "Derrotas"],
			colors: ["#33D491", "#ECDC4D", "#ff4d4d", "#6258cc", "#5148a8", "#3f3984", "#2e2960", "#0b0a18"],
			legend: { position: "right", fontWeight: 600, labels: { colors: "#82868b" } },
		},
		pieOptsZero: {
			labels: ["Sin resultados"],
			colors: ["rgb(153, 153, 153)"],
			legend: { position: "right", fontWeight: 600, labels: { colors: "rgb(153, 153, 153)" } },
			tooltip: {
				enabled: false,
			},
			dataLabels: {
				// valida si hay linea desconocida en el primer index del array de lineas para saber si mostrar etiquetas
				formatter: (val, opts) => {
					return ""
				},
			},
		},
	}),
	methods: {
		efectivityColor(idx) {
			switch (idx) {
				case 0:
					return "text-success"
				case 1:
					return "text-warning"
				case 2:
					return "text-danger"
				case 3:
					return "text-info"
				default:
					return "text-secondary"
			}
		},
		async getData() {
			this.isLoading = true

			const [{ data: lData }, { data: eData }] = await Promise.all([
				tournamentService.getDsOriginResults({
					start_date: this.season.start,
					end_date: this.season.end,
					origin_id: 1,
				}),
				tournamentService.getDsOriginResults({
					start_date: this.season.start,
					end_date: this.season.end,
					origin_id: null,
				}),
			])
			this.efectivities = {
				litter: lData.effectivity,
				external: eData.effectivity,
			}
			this.litterData = lData.results.map((r) => r.counter)
			if (this.sumZero(this.litterData) == 0) {
				this.litterData = [1]
			}
			this.externalData = eData.results.map((r) => r.counter)
			if (this.sumZero(this.externalData) == 0) {
				this.externalData = [1]
			}

			this.isLoading = false
		},
		sumZero(arr = []) {
			return arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
		},
	},
	created() {
		this.getData()
	},
	watch: {
		"season.start"() {
			this.getData()
		},
	},
}
</script>

<style scoped lang="scss">
.title {
	display: block;
	margin-bottom: 2rem;
	color: var(--primary);
	font-size: 1.5rem;
	font-weight: 700;
}

.Card {
	border-radius: 0.5rem;
	box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
}

.Card__container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
}

.Card__title {
	color: var(--secondary);
	font-weight: 600;
	font-size: 12px;
	@media screen and (min-width: 1200px) {
		font-size: 16px;
	}
}

.Card__effectivity {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.2rem 0.5rem;
	color: var(--primary);
	background: rgba(115, 103, 240, 0.2);
	border-radius: 0.5rem;
	font-weight: 500;
	font-size: 12px;

	@media screen and (min-width: 1200px) {
		font-size: 14px;
	}
}
</style>

<style>
.Card .apexcharts-legend {
	justify-content: center !important;
	top: 0 !important;
}
</style>
