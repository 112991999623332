<template>
	<b-overlay class="mb-2 card p-2" :show="isLoading">
		<span class="title">GASTOS</span>
		<b-row class="gap-y-2">
			<b-col cols="12" lg="12">
				<div class="Card">
					<b-row class="gap-y-1 p-1">
						<b-col cols="12" md="6">
							<b-input-group prepend="Filtrar por monto" size="sm" style="width: 17rem; max-width: 20rem">
								<b-form-select
									:options="chartTypeOpts"
									v-model="chartType"
									@change="changeMonthlyChart"
								/>
							</b-input-group>
						</b-col>
						<b-col cols="12" md="6" class="d-flex justify-content-md-end align-items-center">
							<span class="Card__effectivity Card__effectivity--month">
								<feather-icon class="mr-05" icon="CircleIcon" />
								MES
							</span>
						</b-col>
					</b-row>
					<div class="Card__container" v-if="false">
						<span></span>
						<span class="Card__effectivity Card__effectivity--month">
							<feather-icon class="mr-05" icon="CircleIcon" />
							MES
						</span>
					</div>
					<div class="container-bars">
						<div class="bars">
							<VueApexCharts type="bar" height="400" :options="monthlyOpts" :series="monthlyData" />
						</div>
					</div>
				</div>
			</b-col>
			<b-col cols="12" lg="12">
				<div class="Card">
					<div class="Card__container">
						<span></span>
						<span class="Card__effectivity Card__effectivity--tournament">
							<feather-icon class="mr-05" icon="CircleIcon" />
							TORNEO
						</span>
					</div>
					<div class="container-bars">
						<div class="bars">
							<VueApexCharts
								ref="tChart"
								type="bar"
								height="400"
								:options="tournamentOpts"
								:series="tournamentData"
							/>
						</div>
					</div>
				</div>
			</b-col>
		</b-row>
	</b-overlay>
</template>

<script>
import tournamentService from "@/services/tournament/default.service"
import labelUtil from "../chartLabelUtil"

import VueApexCharts from "vue-apexcharts"

export default {
	components: { VueApexCharts },
	props: { season: Object },
	data: () => ({
		isLoading: false,
		chartTypeOpts: [
			{ value: null, text: "Total" },
			{ value: 1, text: "Frentes" },
			{ value: 2, text: "Viáticos" },
		],
		chartType: null,
		monthlyRawData: [],
		monthlyData: [
			{
				name: "Precio",
				data: [],
			},
		],
		monthlyOpts: {
			labels: [],
			colors: ["#00cfe8", "#28c76f", "#ff9f43", "#ea5455"],
			legend: { position: "bottom" },
			plotOptions: { bar: { borderRadius: 8, borderRadiusApplication: "end" } },
		},
		tournamentData: [
			{
				name: "Precio",
				data: [],
			},
		],
		tournamentOpts: {
			labels: [],
			colors: ["#00cfe8", "#28c76f", "#ff9f43", "#ea5455"],
			legend: { position: "bottom" },
			xaxis: { labels: { minHeight: 40 } },
			plotOptions: { bar: { borderRadius: 8, borderRadiusApplication: "end" } },
		},
		tournamentAvatars: [],
	}),
	methods: {
		async getData() {
			this.isLoading = true

			const [{ data: mData }, { data: tData }] = await Promise.all([
				tournamentService.getDsMonthlyExpenses({
					start_date: this.season.start,
					end_date: this.season.end,
				}),
				tournamentService.getDsTournamentExpenses({
					start_date: this.season.start,
					end_date: this.season.end,
				}),
			])

			// monthly
			this.monthlyRawData = mData
			this.monthlyData = [
				{
					name: "Gasto total",
					data: mData.map((m) => m.total),
				},
			]
			this.monthlyOpts = {
				labels: mData.map((m) => m.name),
				colors: ["#8095FF"],
				legend: { position: "bottom" },
				tooltip: {
					y: {
						formatter: (a) =>
							new Intl.NumberFormat("es-PE", {
								style: "currency",
								currency: "PEN",
							}).format(a),
					},
				},
				dataLabels: {
					formatter: function (value) {
						const res =
							Math.abs(value) > 999
								? Math.sign(value) * (Math.abs(value) / 1000).toFixed(1) + "k"
								: Math.sign(value) * Math.abs(value)

						return `S/ ${res}`
					},
					style: { colors: ["#fff"] },
				},
				fill: {
					type: "gradient",
					gradient: {
						shade: "light",
						type: "horizontal",
						shadeIntensity: 0.25,
						gradientToColors: undefined,
						inverseColors: true,
						opacityFrom: 0.85,
						opacityTo: 0.85,
						stops: [50, 0, 100],
					},
				},
				yaxis: {
					title: {
						text: "Total de gastos (k) miles",
						style: {
							color: "#7367f0",
						},
					},
					labels: {
						style: {
							colors: ["#9aa0ac"],
						},
					},
				},
			}

			this.tournamentData = [
				{
					name: "Gasto total",
					data: tData.map((t) => t.total),
				},
			]
			this.tournamentOpts = {
				labels: tData.map((t) => t.tournament_name),
				colors: ["#ff6f2a"],
				legend: { position: "bottom" },
				plotOptions: { bar: { borderRadius: 8, borderRadiusApplication: "end" } },
				xaxis: {
					labels: { minHeight: 50, formatter: () => "." },
				},
				tooltip: {
					x: { formatter: (a) => a },
					y: {
						formatter: (a) =>
							new Intl.NumberFormat("es-PE", {
								style: "currency",
								currency: "PEN",
							}).format(a),
					},
				},
				dataLabels: {
					formatter: function (value) {
						const res =
							Math.abs(value) > 999
								? Math.sign(value) * (Math.abs(value) / 1000).toFixed(1) + "k"
								: Math.sign(value) * Math.abs(value)

						return `S/ ${res}`
					},
					style: { colors: ["#fff"] },
				},
				fill: {
					type: "gradient",
					gradient: {
						shade: "light",
						type: "horizontal",
						shadeIntensity: 0.25,
						gradientToColors: undefined,
						inverseColors: true,
						opacityFrom: 0.85,
						opacityTo: 0.85,
						stops: [50, 0, 100],
					},
				},
				yaxis: {
					title: {
						text: "Total de gastos (k) miles",
						style: {
							color: "#ff6f2a",
						},
					},
					labels: {
						style: {
							colors: ["#9aa0ac"],
						},
					},
				},
			}
			this.tournamentAvatars = tData.map((t) => t.avatar_thumb)
			setTimeout(() => this.setLabelImages(), 500)
			this.isLoading = false
		},
		setLabelImages() {
			const chart = this.$refs.tChart
			if (!chart) return
			const el = chart.$el
			const svg = el.querySelector("svg")
			const defs = svg.querySelector("defs")

			const labels = Array.from(el.querySelectorAll(".apexcharts-xaxis-label"))
			const titles = labels.map((s) => s.querySelector("tspan"))
			titles.forEach((el, idx) => {
				const box = el.getBBox()
				const mdX = box.x + box.width / 2
				const mdY = box.y + box.height / 2
				const pos = labelUtil.convertCoords(mdX, mdY, svg, el)
				const label = { x: pos.x, y: pos.y }
				const imgId = labelUtil.defineBgImage(defs, this.tournamentAvatars, idx)
				labelUtil.createSvgImage(svg, label, imgId)
			})
		},
		changeMonthlyChart() {
			let data = []
			switch (this.chartType) {
				case null:
					data = this.monthlyRawData.map((m) => Number(m.total))
					break
				case 1:
					data = this.monthlyRawData.map((m) => Number(m.fronts))
					break
				case 2:
					data = this.monthlyRawData.map((m) => Number(m.amount))
					break
			}
			this.monthlyData = [
				{
					name: "Gasto total",
					data,
				},
			]
		},
	},
	created() {
		this.getData()
	},
	watch: {
		"season.start"() {
			this.getData()
		},
	},
}
</script>

<style scoped>
.title {
	display: block;
	margin-bottom: 2rem;
	color: var(--primary);
	font-size: 1.5rem;
	font-weight: 700;
}

.Card {
	border-radius: 0.5rem;
	box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
}

.Card__container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
}

.Card__title {
	color: var(--primary);
	font-size: 1.2rem;
	font-weight: 600;
}

.Card__effectivity {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.2rem 0.5rem;
	border-radius: 0.5rem;
	font-weight: 500;
}

.Card__effectivity--month {
	color: #8095ff;
	background: rgba(128, 149, 255, 0.2);
}

.Card__effectivity--tournament {
	color: #ff6f2a;
	background: rgba(255, 111, 42, 0.2);
}
</style>

<style scoped lang="scss">
.container-bars {
	overflow: auto;
	.bars {
		min-width: 1000px;
	}
}
</style>
