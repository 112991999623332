<template>
	<b-overlay class="mb-2" :show="isLoading">
		<b-row class="gap-y-2" :key="checkTrigger">
			<b-col cols="12" md="6" lg="3">
				<div class="bg-white Eftvt Eftvt--triumph">
					<div class="Eftvt__container">
						<div>
							<span class="Eftvt__name">{{ efectivity[0].field }}:</span>
							<span
								class="Eftvt__amount"
								@click=";(list.info = { season, _result: 1 }), (list.show = !list.show)"
							>
								{{ efectivity[0].quantity }}
							</span>
						</div>
						<div>
							<v-icon class="Eftvt__icon Eftvt__icon--triumph" name="fa-trophy" scale="2" />
						</div>
					</div>
					<hr class="Eftvt__separator" />
					<span :class="['Eftvt__variation', getColorText(efectivity[0].variation, 1), 'bg-none']">
						<template v-if="efectivity[0].variation != 0">
							<feather-icon icon="ArrowUpIcon" size="20" v-if="efectivity[0].variation >= 0" />
							<feather-icon icon="ArrowDownIcon" size="20" v-else />
							<span class="font-weight-bolder" v-if="efectivity[0].variation >= 0">+</span>
						</template>
						<span class="font-weight-bolder">{{ efectivity[0].variation }}%</span>
					</span>
					<span class="text-secondary ml-05">Desde el ultimo mes</span>
				</div>
			</b-col>
			<b-col cols="12" md="6" lg="3">
				<div class="bg-white Eftvt Eftvt--draw">
					<div class="Eftvt__container">
						<div>
							<span class="Eftvt__name">{{ efectivity[1].field }}:</span>
							<span
								class="Eftvt__amount"
								@click=";(list.info = { season, _result: 2 }), (list.show = !list.show)"
							>
								{{ efectivity[1].quantity }}
							</span>
						</div>
						<div>
							<v-icon class="Eftvt__icon Eftvt__icon--draw" name="fa-handshake" scale="2" />
						</div>
					</div>
					<hr class="Eftvt__separator" />
					<span :class="['Eftvt__variation', getColorText(efectivity[1].variation, 2), 'bg-none']">
						<template v-if="efectivity[1].variation != 0">
							<feather-icon icon="ArrowUpIcon" size="20" v-if="efectivity[1].variation >= 0" />
							<feather-icon icon="ArrowDownIcon" size="20" v-else />
							<span class="font-weight-bolder" v-if="efectivity[1].variation >= 0">+</span>
						</template>
						<span class="font-weight-bolder">{{ efectivity[1].variation }}%</span>
					</span>
					<span class="text-secondary ml-05">Desde el ultimo mes</span>
				</div>
			</b-col>
			<b-col cols="12" md="6" lg="3">
				<div class="bg-white Eftvt Eftvt--loss">
					<div class="Eftvt__container">
						<div>
							<span class="Eftvt__name">{{ efectivity[2].field }}:</span>
							<span
								class="Eftvt__amount"
								@click=";(list.info = { season, _result: 3 }), (list.show = !list.show)"
							>
								{{ efectivity[2].quantity }}
							</span>
						</div>
						<div>
							<v-icon class="Eftvt__icon Eftvt__icon--loss" name="fa-skull-crossbones" scale="2" />
						</div>
					</div>
					<hr class="Eftvt__separator" />
					<span :class="['Eftvt__variation', getColorText(efectivity[2].variation, 3), 'bg-none']">
						<template v-if="efectivity[2].variation != 0">
							<feather-icon icon="ArrowUpIcon" size="20" v-if="efectivity[2].variation >= 0" />
							<feather-icon icon="ArrowDownIcon" size="20" v-else />
							<span class="font-weight-bolder" v-if="efectivity[2].variation >= 0">+</span>
						</template>

						<span class="font-weight-bolder">{{ efectivity[2].variation }}%</span>
					</span>
					<span class="text-secondary ml-05">Desde el ultimo mes</span>
				</div>
			</b-col>
			<b-col cols="12" md="6" lg="3">
				<div class="Eftvt Eftvt--total">
					<div class="Eftvt__container">
						<div>
							<span class="Eftvt__name Eftvt__name--total">{{ efectivity[3].field }}</span>
							<span class="Eftvt__amount Eftvt__amount--total">{{ efectivity[3].quantity }}%</span>
						</div>
						<div></div>
					</div>
					<hr class="Eftvt__separator" />
					<span style="color:trasparent">---</span>
				</div>
			</b-col>
		</b-row>
		<DsGeneralListModal :show="list.show" :info="list.info" @closing="list.show = !list.show" />
	</b-overlay>
</template>

<script>
import tournamentService from "@/services/tournament/default.service"

import VueApexCharts from "vue-apexcharts"

import DsGeneralListModal from "./DsGeneralListModal.vue"

export default {
	components: { VueApexCharts, DsGeneralListModal },
	props: { season: Object },
	data: () => ({
		isLoading: false,
		checkTrigger: 0,
		efectivity: [
			{ field: "Triunfos", quantity: 99, variation: 0 },
			{ field: "Empates", quantity: 99, variation: 0 },
			{ field: "Derrotas", quantity: 99, variation: 0 },
			{ field: "Efectividad:", quantity: 99, variation: 0 },
		],
		list: { show: false, info: {} },
	}),
	methods: {
		efectivityColor(idx) {
			switch (idx) {
				case 0:
					return "text-success"
				case 1:
					return "text-warning"
				case 2:
					return "text-danger"
				case 3:
					return "text-info"
				default:
					return "text-secondary"
			}
		},
		async getData() {
			this.isLoading = true
			const { data } = await tournamentService.getDsGeneralResults({
				start_date: this.season.start,
				end_date: this.season.end,
			})
			const triumph = data.results.find((r) => r.id == 1)
			this.efectivity[0].quantity = triumph.counter
			this.efectivity[0].variation = triumph.percentage
			const draw = data.results.find((r) => r.id == 2)
			this.efectivity[1].quantity = draw.counter
			this.efectivity[1].variation = draw.percentage
			const loss = data.results.find((r) => r.id == 3)
			this.efectivity[2].quantity = loss.counter
			this.efectivity[2].variation = loss.percentage

			this.efectivity[3].quantity = data.effectivity
			this.efectivity[3].variation = data.effectivity_percentage
			this.checkTrigger++
			this.isLoading = false
		},
		getColorText(num, tab) {
			let clase = "Eftvt__icon--empate"

			if (tab == 1) {
				if (num < 0) {
					clase = "Eftvt__icon--loss"
				} else if (num > 0) {
					clase = "Eftvt__icon--triumph"
				}
			} else {
				if (num > 0) {
					clase = "Eftvt__icon--loss"
				} else if (num < 0) {
					clase = "Eftvt__icon--triumph"
				}
			}
			return clase
		},
	},
	created() {
		this.getData()
	},
	watch:{
		'season.start'(){
			this.getData()
		}
	}
}
</script>

<style scoped>
.Eftvt {
	padding: 1rem;
	border-radius: 0.5rem;
	box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
}

.Eftvt--triumph {
	border-top: 0.5rem solid #33d491;
}
.Eftvt--draw {
	border-top: 0.5rem solid #ecdc4d;
}
.Eftvt--loss {
	border-top: 0.5rem solid #ff4d4d;
}

.Eftvt--total {
	background: var(--primary);
	border-top: 0.5rem solid rgba(0, 0, 0, 0);
}

.Eftvt__container {
	display: flex;
	justify-content: space-between;
}

.Eftvt__name {
	display: block;
	margin-bottom: 0.5rem;
	color: var(--secondary);
	font-size: 1.5rem;
	font-weight: 600;
}

.Eftvt__name--total {
	color: #fff;
}

.Eftvt__amount {
	display: block;
	color: #000;
	font-size: 4rem;
	font-weight: 500;
	line-height: 100%;
	cursor: pointer;
}

.Eftvt__amount--total {
	cursor: inherit;
	color: #fff;
}

.Eftvt__icon {
	display: block;
	padding: 0.5rem;
	border-radius: 0.5rem;
}

.Eftvt__icon--triumph {
	color: #33d491 !important;
	background: rgba(51, 212, 145, 0.2);
}
.Eftvt__icon--draw {
	color: #ecdc4d !important;
	background: rgba(236, 220, 77, 0.2);
}
.Eftvt__icon--loss {
	color: #ff4d4d !important;
	background: rgba(255, 77, 77, 0.2);
}
.Eftvt__icon--empate {
	color: var(--secondary) !important;
	/* background: rgba(255, 77, 77, 0.2); */
}

.Eftvt__separator {
	border-width: 0.2rem;
}

.Eftvt__separator--total {
	border-color: #fff;
}

.Eftvt__variation {
	/* color: var(--success); */
	font-size: 1.2rem;
	font-weight: 500;
}

.Eftvt__variation--loss {
	color: var(--danger);
}

.Eftvt__variation--total {
	color: #fff;
}
.bg-none {
	background: none !important;
}
</style>
